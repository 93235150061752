import styled from "styled-components";

export const Wrapper = styled.span<{
  readonly fontSize: string;
}>`
  width: fit-content;
  font-size: ${({ theme, fontSize }) => theme.fontsizes[fontSize]};
`;

export const SlashImg = styled.img`
  padding: 0 8px;
`;
