import CurrentBreadcrumb from "@Components/CurrentBreadcrumb";
import { Date } from "@Components/SessionCardContent/SessionCardContent";
import { COUNTRY } from "@Constants/Breadcrumb";
import { NavDropdownContext } from "@Context/NavDropdown.context";
import { getAlgoliaDefaultBreadcrumb, getAlgoliaDefaultName } from "@Utils/Strings";
import dayjs from "dayjs";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { Fragment, ReactElement, useContext, useState } from "react";
import { Hit } from "react-instantsearch-core";
import { AlgoliaInstructor, AlgoliaLiveSession, AlgoliaPackages, AlgoliaSubject } from "types/AlgoliaSearch";
import {
  BreadCrumbWrapper,
  Point,
  SearchCardImg,
  SearchCardInfo,
  SearchCardSubTitle,
  SearchCardTitle,
  SearchCardWrapper,
} from "../Navbar.styled";

type Props =
  | {
      data: Hit<AlgoliaSubject>;
      type: "subject";
    }
  | {
      data: Hit<AlgoliaPackages>;
      type: "packages";
    }
  | {
      data: Hit<AlgoliaInstructor>;
      type: "instructor";
    }
  | {
      data: Hit<AlgoliaLiveSession>;
      type: "live";
    };

export default function SearchResultCard({ data, type }: Props): ReactElement {
  const { locale, push } = useRouter();
  const { t: tLanding } = useTranslation("Landing");
  const { setDropDownMenu } = useContext(NavDropdownContext);
  const [loadImageError, setLoadImageError] = useState(false);

  const DropdownClickHandler = () => {
    if (type === "subject") {
      push(`/${type}s/${(data as AlgoliaSubject).slug}`);
    } else if (type === "instructor") {
      push(`/${(data as AlgoliaInstructor).user_name}`);
    } else if (type === "live") {
      push(`/${type}/${(data as AlgoliaLiveSession).slug}`);
    } else if (type === "packages") {
      push(`/${(data as AlgoliaPackages).link}`);
    }
    setDropDownMenu(false);
  };

  return (
    <SearchCardWrapper onClick={DropdownClickHandler}>
      <SearchCardImg
        src={type === "packages" ? "/images/package/package.svg" : (data as AlgoliaSubject).image}
        as={!loadImageError && Boolean((data as AlgoliaSubject).image) ? "img" : type === "packages" ? "img" : "div"}
        onError={() => setLoadImageError(true)}
        alt={(data as AlgoliaSubject).name[locale]}
        isCircle={type === "instructor" || type === "live"}
        isPackage={type === "packages"}
      />
      <SearchCardInfo>
        {type === "live" && (
          <Date>{`${dayjs
            .utc((data as AlgoliaLiveSession).start_at * 1000)
            .local()
            .format("MMM D, hh:mm A ")}-${dayjs
            .utc((data as AlgoliaLiveSession).ended_at * 1000)
            .local()
            .format(" hh:mm A")}`}</Date>
        )}
        <SearchCardTitle>
          {type === "subject" || type === "packages"
            ? ((data as AlgoliaSubject) || (data as AlgoliaPackages)).name[
                getAlgoliaDefaultName((data as AlgoliaSubject) || (data as AlgoliaPackages), locale)
              ]
            : data.name}
        </SearchCardTitle>
        {type === "subject" && (
          <BreadCrumbWrapper id="breadcrumb-wrapper">
            <CurrentBreadcrumb
              breadcrumb={
                (data as AlgoliaSubject).breadcrumbs_array[getAlgoliaDefaultBreadcrumb(data as AlgoliaSubject, locale)]
                  .length > 0
                  ? (data as AlgoliaSubject).breadcrumbs_array[
                      getAlgoliaDefaultBreadcrumb(data as AlgoliaSubject, locale)
                    ][0]
                      .flat()
                      .map((item, index) => ({
                        name: item,
                        short_name: item,
                        emoji: item,
                        ...(index === 0 ? { type: COUNTRY } : {}),
                      }))
                  : (data as AlgoliaSubject).breadcrumbs_array[
                      getAlgoliaDefaultBreadcrumb(data as AlgoliaSubject, locale)
                    ]
                      .flat()
                      .map((item, index) => ({
                        name: item,
                        short_name: item,
                        emoji: item,
                        ...(index === 0 ? { type: COUNTRY } : {}),
                      }))
              }
              fontSize={"body16"}
              parentElementID={"breadcrumb-wrapper"}
            />
          </BreadCrumbWrapper>
        )}
        {type === "packages" && (
          <BreadCrumbWrapper id="breadcrumb-wrapper">
            <CurrentBreadcrumb
              breadcrumb={
                (data as AlgoliaPackages).breadcrumbs_array[
                  getAlgoliaDefaultBreadcrumb(data as AlgoliaPackages, locale)
                ].length > 0
                  ? (data as AlgoliaPackages).breadcrumbs_array[
                      getAlgoliaDefaultBreadcrumb(data as AlgoliaPackages, locale)
                    ][0]
                      .flat()
                      .map((item, index) => ({
                        name: item,
                        short_name: item,
                        emoji: item,
                        ...(index === 0 ? { type: COUNTRY } : {}),
                      }))
                  : (data as AlgoliaPackages).breadcrumbs_array[
                      getAlgoliaDefaultBreadcrumb(data as AlgoliaPackages, locale)
                    ]
                      .flat()
                      .map((item, index) => ({
                        name: item,
                        short_name: item,
                        emoji: item,
                        ...(index === 0 ? { type: COUNTRY } : {}),
                      }))
              }
              fontSize={"body16"}
              parentElementID={"breadcrumb-wrapper"}
            />
          </BreadCrumbWrapper>
        )}
        <SearchCardSubTitle>
          {type !== "instructor" ? (
            type === "subject" ? (
              (data as AlgoliaSubject).instructor?.name
            ) : type === "packages" ? (
              `${(data as AlgoliaPackages).subjects.length} ${tLanding("subjects")}`
            ) : type === "live" ? (
              (data as AlgoliaLiveSession).instructor?.name
            ) : (
              ""
            )
          ) : (
            <>
              {(data as AlgoliaInstructor).subjects_count > 0 && (
                <p>
                  {(data as AlgoliaInstructor).subjects_count} {tLanding("subjects")}
                </p>
              )}
              {(data as AlgoliaInstructor).subscribers_count > 0 && (
                <Fragment>
                  {(data as AlgoliaInstructor).subjects_count > 0 && <Point />}
                  <p>
                    {(data as AlgoliaInstructor).subscribers_count} {tLanding("subscribers")}
                  </p>
                </Fragment>
              )}
            </>
          )}
        </SearchCardSubTitle>
      </SearchCardInfo>
    </SearchCardWrapper>
  );
}
