import { ArrowDirectionWrapper } from "@Components/LandingPage/HeroArea/HeroArea.styled";
import { _GetCategories } from "@Services/Home";
import { useRouter } from "next/router";
import LightArrowRightSvgIcon from "public/svgs/icons/global-icons/LightArrowRightSvgIcon";
import React, { Fragment, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useQuery } from "react-query";
import { Category } from "types/homepage";
import {
  ExploreDropdowLink,
  ExploreDropdownContent,
  ExploreDropdownLi,
  MainExploreDropdown,
  RecursiveMenuWrapper,
} from "../Navbar.styled";

interface Props {
  depth: number;
  hoveredCategory: Category;
  series: string[];
}

const LinkLoader = (props) => (
  <ContentLoader speed={2} width={"100%"} height={24} backgroundColor="#f3f3f3" foregroundColor="#ecebeb" {...props}>
    <rect x="0" y="5" rx="3" ry="3" width="100" height="14" />
  </ContentLoader>
);

export default function RecursiveExploreMenu({ hoveredCategory, depth, series }: Props) {
  const { locale, route, push } = useRouter();
  const [rootCategoryHover, setRootCategoryHover] = useState<Category>();
  const { data: categoriesData, isLoading } = useQuery(
    ["onboarding-categories", { locale, categoryId: hoveredCategory.id }],
    () => _GetCategories(hoveredCategory.id),
    {
      refetchOnMount: false,
      staleTime: 5 * 1000 * 60, // 5 minutes
    }
  );

  const getCatLink = (catSlug: String) => `/home/${series.join("/")}/${catSlug}`;

  const handleShallowRouting = (e: React.MouseEvent<Element, MouseEvent>, catSlug: string) => {
    e.preventDefault();
    push(getCatLink(catSlug), undefined, {
      shallow: true,
      scroll: true,
    });
  };

  useEffect(() => {
    setRootCategoryHover(undefined);
  }, [hoveredCategory]);

  return (
    <Fragment>
      <RecursiveMenuWrapper depth={depth}>
        <MainExploreDropdown isOpen={Boolean(hoveredCategory)} position="left" isRecursive>
          {isLoading
            ? [...new Array(4)].map((_, i) => (
                <ExploreDropdownLi key={"SKELETON_EXPLORE_" + i}>
                  <ExploreDropdowLink as="div">
                    <ExploreDropdownContent>
                      <LinkLoader />
                    </ExploreDropdownContent>
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))
            : categoriesData?.data.options.map((cat) => (
                <ExploreDropdownLi key={cat.id}>
                  <ExploreDropdowLink
                    href={getCatLink(cat.slug)}
                    onClick={(e) => (route === "/home/[[...slug]]" ? handleShallowRouting(e, cat.slug) : null)}
                    onMouseEnter={() => setRootCategoryHover(cat)}
                  >
                    <ExploreDropdownContent>
                      {cat.emoji ? <span className="emojie">{cat.emoji}&nbsp;</span> : ""}
                      {cat.name}
                    </ExploreDropdownContent>
                    {cat.has_children && (
                      <ArrowDirectionWrapper>
                        <LightArrowRightSvgIcon width="6.5" height="11.5" color="#1a1a1a" />
                      </ArrowDirectionWrapper>
                    )}
                  </ExploreDropdowLink>
                </ExploreDropdownLi>
              ))}
        </MainExploreDropdown>
      </RecursiveMenuWrapper>
      {rootCategoryHover && rootCategoryHover.has_children && depth < 3 && (
        <RecursiveExploreMenu
          depth={depth + 1}
          hoveredCategory={rootCategoryHover}
          series={[...series, rootCategoryHover.slug]}
        />
      )}
    </Fragment>
  );
}
