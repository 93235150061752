import { ArrowDirectionWrapper } from "@Components/LandingPage/HeroArea/HeroArea.styled";
import { _GetCategories } from "@Services/Home";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import LightArrowRightSvgIcon from "public/svgs/icons/global-icons/LightArrowRightSvgIcon";
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useClickAway } from "react-use";
import { Category } from "types/homepage";
import {
  ExploreDropdowLink,
  ExploreDropdownContent,
  ExploreDropdownLi,
  ExploreDropdownWrapper,
  MainExploreDropdown,
  MainExploreMenuWrapper,
  NavLink,
} from "../Navbar.styled";
import RecursiveExploreMenu from "./RecursiveExploreMenu";

interface Props {
  setShowBlurredBg: Dispatch<SetStateAction<boolean>>;
}

export default function ExploreDropdown({ setShowBlurredBg }: Props) {
  const { t } = useTranslation("common");
  const { locale, asPath, route, push } = useRouter();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [rootCategoryHover, setRootCategoryHover] = useState<Category>();
  const { data: categoriesData, isLoading } = useQuery(["onboarding-categories", { locale }], () => _GetCategories(), {
    refetchOnMount: false,
  });

  useClickAway(dropdownRef, () => {
    setIsDropdownOpen(false);
  });

  useEffect(() => {
    setIsDropdownOpen(false);
  }, [locale]);

  useEffect(() => {
    setShowBlurredBg(isDropdownOpen);
    setRootCategoryHover(undefined);
    if (window) {
      if (isDropdownOpen) {
        window.addEventListener("mousemove", checkHover);
      } else {
        window.removeEventListener("mousemove", checkHover);
      }
    }
  }, [isDropdownOpen]);

  useEffect(() => {
    setIsDropdownOpen(false);
    setRootCategoryHover(undefined);
  }, [asPath]);

  const handleShallowRootCategory = (e: React.MouseEvent<Element, MouseEvent>, catSlug: string) => {
    e.preventDefault();
    push(`/home/${catSlug}`, undefined, {
      shallow: true,
      scroll: true,
    });
    setIsDropdownOpen(false);
  };

  const checkHover = useCallback((e: MouseEvent) => {
    if (!dropdownRef?.current?.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  }, []);

  return (
    <ExploreDropdownWrapper ref={dropdownRef} onMouseEnter={() => !isLoading && setIsDropdownOpen(true)}>
      <NavLink as="div">{t("explore")}</NavLink>
      <MainExploreMenuWrapper isOpen={isDropdownOpen}>
        <MainExploreDropdown isOpen={isDropdownOpen} position="left">
          {categoriesData?.data.options.map((cat) => (
            <ExploreDropdownLi key={cat.id}>
              <ExploreDropdowLink
                href={`/home/${cat.slug}`}
                onClick={(e) => (route === "/home/[[...slug]]" ? handleShallowRootCategory(e, cat.slug) : null)}
                onMouseEnter={() => setRootCategoryHover(cat)}
              >
                <ExploreDropdownContent>
                  {cat.emoji ? <span className="emojie">{cat.emoji}&nbsp;</span> : ""}
                  {cat.name}
                </ExploreDropdownContent>
                {cat.has_children && (
                  <ArrowDirectionWrapper>
                    <LightArrowRightSvgIcon width="6.5" height="11.5" color="#1a1a1a" />
                  </ArrowDirectionWrapper>
                )}
              </ExploreDropdowLink>
            </ExploreDropdownLi>
          ))}
        </MainExploreDropdown>
      </MainExploreMenuWrapper>
      {rootCategoryHover && rootCategoryHover.has_children && (
        <RecursiveExploreMenu depth={0} hoveredCategory={rootCategoryHover} series={[rootCategoryHover.slug]} />
      )}
    </ExploreDropdownWrapper>
  );
}
